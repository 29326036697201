import React from 'react'
import styled from 'styled-components'
import Sticky from 'react-stickynode'
import { Box, Container, Card } from 'pcln-design-system'

const StickyNode = styled(Sticky)`
  > div {
    left: 0;
  }
`

type StickyEffectProps = {
  enabled: boolean
  isStickyInEffect: boolean
  children?: React.ReactNode
  handleStickyEffectStateChange?: (state: Sticky.Status) => void
}

export default function StickyEffect({
  children,
  handleStickyEffectStateChange,
  enabled,
  isStickyInEffect
}: StickyEffectProps) {
  return (
    <StickyNode
      enabled={enabled}
      innerZ={1}
      onStateChange={handleStickyEffectStateChange}
      enableTransforms={false}
    >
      {isStickyInEffect ? (
        <Box textAlign="center" width="100vw" pb={1}>
          <Card
            color="text.base"
            boxShadowSize="xl"
            bg="background.lightest"
            borderRadius="3xl"
            borderWidth={0}
            rounded="bottom"
            display="inline-block"
            width={[1, null, null, null, null, 1280]}
          >
            <Container maxWidth={1280} p={4} pt={3} pb={0}>
              {children}
            </Container>
          </Card>
        </Box>
      ) : (
        <Box>
          <Card borderWidth="0">
            <Container>{children}</Container>
          </Card>
        </Box>
      )}
    </StickyNode>
  )
}

/* eslint-disable no-nested-ternary */
import React, {
  useEffect,
  useContext,
  useState,
  useCallback,
  useRef,
  type SyntheticEvent
} from 'react'
import { useRouter } from 'next/router'
import { Box, Flex, Text, Button, Image, Shimmer } from 'pcln-design-system'
import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { Calendar } from '@pcln/date-picker'
import { Controller, useForm } from 'react-hook-form'
import { format } from 'date-fns/format'
import { addDays } from 'date-fns/addDays'
import Sticky from 'react-stickynode'
import { Pin } from 'pcln-icons'
import TravelerSelection from '@pcln/traveler-selection'
import { yupResolver } from '@hookform/resolvers/yup'
import { getHotelDateRange } from '@/shared-utils/date-helper'
import SearchFormButton from '@/components/SearchFormButton'
import TypeAhead from '@/components/TypeAhead'
import { PopoverContainer } from '@/components/Popover'
import ShadowEffect from '@/components/ShadowEffect'
import CalendarWrapperBox from '@/components/CalenderWrapperBox.styled'
import DateField from '@/components/DateField'
import useSeti from '@/hooks/useSeti'
import { VP_DISCLAIMER } from '@/components/TravelerSelection/constants'
import useTabGroup from '@/hooks/useTabGroup'
import StickyEffect from '@/components/StickyEffect'
import BundleAndSave, {
  FlightOption,
  CarOption
} from '@/components/BundleAndSave'
import type { LOCATION_SEARCH_TYPE } from '@/types'
import useRecommendedCityIds from '@/hooks/useRecommendedCityIds'
import useFetchCityMap from '@/hooks/useFetchCityMap'
import { SearchFormContext } from '@/context/SearchFormContext'
import useBootstrapData from '@/hooks/useBootstrapData'
import fireMaxRoomsEvent from '@/components/TravelerSelection/ga4'
import pclnExperimentation from '@pcln/experimentation'
import { GA4PageNameType } from '@/ga4types'
import {
  CALENDAR_POPOVER_SELECTOR,
  PromoDeal,
  FLY,
  DRIVE,
  STAY
} from '../constants'
import { fields, initialState, groupDealsURL } from './utils'
import {
  searchFormSchema,
  isInfantLimitReachedPackages,
  INFANT_CAPACITY_LIMIT
} from './validation'
import {
  PromoDealType,
  HOTEL_FORM_STATE_TYPE,
  HOTEL_FORM_SUBMIT_TYPE,
  SEM_SEARCH_TYPE,
  TravelerSelectionOnChange
} from '../types'

import MultiHotelForm from './MultiHotelSearchForm/Form'
import FormTypeRadios, {
  FormTypeValues
} from './MultiHotelSearchForm/FormTypeRadios'
import { fireBundleAndSaveEvent } from '../ga4'

const StyledRelative = styled(Box)`
  position: relative;
  overflow: hidden;
`

const StyledAbsolute = styled(Box)`
  position: absolute;
  top: ${themeGet('space.4')}px;
  right: ${themeGet('space.3')}px;
`

type FormType = {
  onSubmit: (data: HOTEL_FORM_SUBMIT_TYPE, pageName: GA4PageNameType) => void
  promoDeal?: PromoDealType
  setLocation?: (city?: LOCATION_SEARCH_TYPE) => void
  showMultiHotel?: boolean
  isLandingPage?: boolean
}

export default function Form({
  onSubmit,
  promoDeal,
  setLocation = () => {
    // if pre-pop isn't used
  },
  showMultiHotel = false,
  isLandingPage = true
}: FormType) {
  const [isHotelCalendarOpen, setIsHotelCalendarOpen] = useState(false)
  const { registerTabElement, focusNextElement } = useTabGroup()
  const {
    isMobile,
    prePopData,
    signInStatus: { signedIn }
  } = useBootstrapData()
  const { updateFormValues } = useContext(SearchFormContext)

  const {
    endLocation,
    startDate: prePopStartDate,
    roomCount
  } = prePopData?.hotels ?? {}
  const [isStickyInEffect, setIsStickyInEffect] = useState(false)
  const enableStickyEffect = !isMobile && !promoDeal
  const { query, pathname } = useRouter()

  const isHomepage = pathname === '/'
  const ORIGINAL_MOBILE_CITY_MAP_HEIGHT = 130
  const MOBILE_CITY_MAP_HEIGHT_CONTAINER = 80

  const { SEMSearch, SEMSEARCH, 'ambig-hotel-city': ambigHotelCity } = query

  const isSemUSOutbound =
    !isHomepage &&
    !!endLocation?.countryCode &&
    endLocation?.countryCode !== 'US'

  const initialValues = {
    ...initialState,
    ...(endLocation && { endLocation }),
    ...(!isSemUSOutbound && {
      startDate: prePopStartDate || format(new Date(), 'yyyyMMdd'),
      endDate: prePopStartDate || format(addDays(new Date(), 1), 'yyyyMMdd')
    }),
    ...(roomCount && { roomCount })
  }

  const isMobileFormInputSmaller = isMobile && !isHomepage
  const {
    clearErrors,
    register,
    control,
    unregister,
    setValue,
    getValues,
    handleSubmit,
    watch,
    trigger,
    formState: { errors, isSubmitting }
  } = useForm<HOTEL_FORM_STATE_TYPE>({
    defaultValues: initialValues,
    mode: 'onSubmit',
    resolver: yupResolver(searchFormSchema),
    reValidateMode: 'onChange'
  })
  const [cityMapWidth, setCityMapWidth] = useState<number | undefined>(
    undefined
  )
  useEffect(() => {
    setCityMapWidth(window.screen.width)
  }, [])

  useEffect(() => {
    fields.forEach(name => {
      register(name)
    })
    return () => {
      fields.forEach(name => {
        unregister(name)
      })
    }
  }, [register, unregister])

  const {
    endLocation: watchEndLocation,
    startLocation: watchStartLocation,
    startDate: watchStartDate,
    endDate: watchEndDate,
    travelers: watchTravelers,
    tripType: watchTripType
  } = watch()
  useEffect(() => {
    if (watchEndLocation) {
      setLocation(watchEndLocation)
    }
  }, [watchEndLocation, setLocation])

  const hasFlight = watchTripType.includes(FLY)
  const hasCar = watchTripType.includes(DRIVE)
  const isHPWithoutStickyEffect = isHomepage && !isStickyInEffect

  const maxDaysToSelect = 30

  // hotel date should be allowed up to 364 days from today
  // default is 330
  const maxSelectableDate =
    !hasFlight && !hasCar
      ? format(addDays(Date.now(), 364), 'yyyyMMdd')
      : undefined

  const { lat, lon, cityName } =
    (prePopData?.hotels?.endLocation as LOCATION_SEARCH_TYPE) ?? {}

  const showCityMapInMobile =
    isMobile && !hasFlight && !hasCar && !isHomepage && !!cityName

  const showCityDisambiguation = !!ambigHotelCity && !!cityName && !isHomepage

  const shouldFetchRecommendedCityIds = isHomepage && signedIn
  const { recommendedCityIds } = useRecommendedCityIds({
    shouldFetchRecommendedCityIds
  })
  const { cityMapURL, fetchCityMap } = useFetchCityMap(
    showCityMapInMobile
      ? {
          lat,
          lon,
          width: cityMapWidth,
          height: ORIGINAL_MOBILE_CITY_MAP_HEIGHT
        }
      : {
          lat: undefined,
          lon: undefined,
          width: undefined,
          height: undefined
        }
  )

  const inputFormMargin = !isMobile || isMobileFormInputSmaller ? 3 : 0

  const isOneLineSearch =
    !isMobile &&
    !promoDeal &&
    (isStickyInEffect || !isHomepage) &&
    (isStickyInEffect || !hasFlight)

  const smbtBtnReplacementGrid = isHPWithoutStickyEffect ? 1 / 2 : 1 / 3
  const smbtBtnReplacementGridRow2 = isHPWithoutStickyEffect ? 1 / 2 : 2 / 5
  const smbtBtnReplacementGridRow3 = isHPWithoutStickyEffect ? 1 / 2 : 1 / 5
  const smbtBtnReplacementGridTypeAhead = isHPWithoutStickyEffect ? 1 : 1 / 2

  const marginBottomButtonWithoutStickySearch = 0
  const marginBottomButtonStickySearch = [4, null, 3]
  const marginButtonBasedOnStickySearch = isStickyInEffect
    ? marginBottomButtonStickySearch
    : marginBottomButtonWithoutStickySearch
  const searchBtnMargin =
    !isMobile && isHPWithoutStickyEffect ? 1 : marginButtonBasedOnStickySearch
  const isDesktopExpressDeal = promoDeal === PromoDeal.EXPRESS_DEAL && !isMobile

  const typeaheadWidth = isOneLineSearch
    ? hasFlight
      ? [1, null, 24 / 100]
      : [1, null, 27 / 100]
    : hasFlight
    ? [1, null, 1 / 2, null, 1 / 2]
    : [1, null, smbtBtnReplacementGridTypeAhead, null, 1]
  const calendarWidth = isDesktopExpressDeal
    ? 1 / 2
    : isOneLineSearch
    ? hasFlight
      ? [1, null, 24 / 100]
      : [1, null, 31 / 100]
    : hasFlight
    ? [1, null, smbtBtnReplacementGridRow2, null, smbtBtnReplacementGrid]
    : [1, null, 1 / 2, null, smbtBtnReplacementGrid]

  const roomSelectionWidth = isDesktopExpressDeal
    ? 1 / 2
    : isOneLineSearch
    ? hasFlight
      ? [1, null, 18 / 100]
      : [1, null, 23 / 100]
    : hasFlight
    ? [1, null, smbtBtnReplacementGridRow2, null, smbtBtnReplacementGrid]
    : [1, null, 1 / 2, null, smbtBtnReplacementGrid]

  const searchButtonWidth = isDesktopExpressDeal
    ? 1
    : isOneLineSearch
    ? hasFlight
      ? [1, null, 10 / 100]
      : [1, null, 19 / 100]
    : hasFlight
    ? [1, null, smbtBtnReplacementGridRow3, null, smbtBtnReplacementGrid]
    : [1, null, 1 / 2, null, smbtBtnReplacementGrid]

  const packageOptionsWidth = isOneLineSearch
    ? hasFlight
      ? [1, null, 10 / 100]
      : [1, null, 19 / 100]
    : [1, null, 1 / 2, null, 1 / 2]

  const hotelPlannerLink = groupDealsURL({
    location: watchEndLocation ? watchEndLocation.itemName : '',
    startDate: watchStartDate,
    endDate: watchEndDate
  })

  const hotelPlannerLinkAttrsProp = {
    href: hotelPlannerLink,
    target: '_blank'
  }

  const handleShowBundleAndSaveWidth =
    isHPWithoutStickyEffect && !isMobile
      ? packageOptionsWidth
      : isMobile
      ? '100%'
      : ''
  const handleStickyEffectStateChange = ({ status }: Sticky.Status) => {
    if (status === Sticky.STATUS_FIXED && !isStickyInEffect) {
      setIsStickyInEffect(true)
    } else if (isStickyInEffect) {
      setIsStickyInEffect(false)
    }
  }

  useEffect(() => {
    if (
      isSubmitting &&
      isMobile &&
      typeof window.PCLN?.pclnEventDispatcher?.dispatch === 'function'
    ) {
      const bookingInfo = {
        cityName: watchEndLocation?.cityName,
        cityId: watchEndLocation?.cityID,
        checkInDate: watchStartDate,
        checkOutDate: watchEndDate
      }
      window.PCLN.pclnEventDispatcher.dispatch(
        'FIND_YOUR_HOTEL_DATA',
        bookingInfo
      )
    }
  }, [isMobile, isSubmitting, watchEndLocation, watchStartDate, watchEndDate])

  const showBundleAndSave = () =>
    !promoDeal && (
      <Box
        width={handleShowBundleAndSaveWidth}
        px={2}
        mb={isMobile || !isHPWithoutStickyEffect ? 3 : 4}
        height="56px"
      >
        <BundleAndSave
          color={isHomepage ? 'highlight.shade' : 'success.dark'}
          productOptions={[CarOption, FlightOption]}
          tripType={watchTripType}
          label="Bundle + Save"
          onSelectionChange={(updatedTripType, checked) => {
            setValue('tripType', updatedTripType)
            if (checked) {
              fireBundleAndSaveEvent(updatedTripType)
            }
          }}
        />
      </Box>
    )
  const showCurrentLocation = !promoDeal && isMobile
  useEffect(() => {
    clearErrors('startLocation')
  }, [clearErrors, watchStartLocation])
  useEffect(() => {
    clearErrors('endLocation')
  }, [clearErrors, watchEndLocation])
  useEffect(() => {
    clearErrors('startDate')
  }, [clearErrors, watchStartDate, watchTripType])
  useEffect(() => {
    clearErrors('endDate')
  }, [clearErrors, watchEndDate, watchTripType])

  useEffect(() => {
    void trigger('travelers.children')
  }, [trigger, watchTripType, watchTravelers])

  useEffect(() => {
    void trigger('travelers.rooms')
  }, [trigger, watchTravelers, watchTripType])
  useEffect(() => {
    if (updateFormValues) {
      updateFormValues(getValues())
    }
  }, [
    getValues,
    updateFormValues,
    watchStartLocation,
    watchEndLocation,
    watchStartDate,
    watchEndDate,
    watchTripType
  ])

  const itemSelected = useCallback(
    (item: LOCATION_SEARCH_TYPE) => {
      if (showCityMapInMobile && item) {
        void fetchCityMap({
          lat: item?.lat,
          lon: item?.lon,
          width: window.screen.width,
          height: ORIGINAL_MOBILE_CITY_MAP_HEIGHT
        })
      }

      if (!isMobile && !!item) {
        focusNextElement()
      } else if (
        isMobile &&
        !!item &&
        document.activeElement instanceof HTMLElement
      ) {
        document.activeElement.blur()
      }
    },
    [showCityMapInMobile, isMobile, fetchCityMap, focusNextElement]
  )

  const ambiguousElement = useRef<HTMLInputElement>()

  function registerAmbiguousElement(ref: HTMLInputElement): void {
    ambiguousElement.current = ref
  }
  const clarifyAmbiguity = useCallback(() => {
    const city = endLocation?.cityName ?? ''
    return setTimeout(() => {
      if (ambiguousElement.current && city) {
        ambiguousElement.current.focus()
        const ambiguousCity = {
          ...endLocation,
          itemName: city
        } as LOCATION_SEARCH_TYPE
        setValue('endLocation', ambiguousCity, { shouldDirty: true })
      }
    }, 0)
  }, [endLocation, setValue])

  const showVPDisclaimer =
    watchTripType.includes(FLY) || watchTripType.includes(DRIVE)

  // PKG_FE_HH_HOTEL_HOME_PAGE PKG_FE_HH_HOTEL_LANDING_PAGE
  const [formType, setFormType] = useState(FormTypeValues.SINGLE)
  const pageName = isLandingPage ? 'landing' : 'homepage'
  useEffect(() => {
    if (isHomepage)
      pclnExperimentation.fireImpression('HP_HOTEL_TYPEAHEAD_PENNY')
  }, [isHomepage])

  const isPennyTypeaheadActive = useSeti('HP_HOTEL_TYPEAHEAD_PENNY', false)
  const shouldDisplayPenny =
    isHomepage &&
    (isPennyTypeaheadActive === 'VARIANT' ||
      isPennyTypeaheadActive === 'VARIANT2')

  return (
    <>
      {showMultiHotel && (
        <FormTypeRadios onChange={setFormType} formType={formType} />
      )}
      {formType === FormTypeValues.MULTI ? (
        <MultiHotelForm
          firstHotel={{
            endDate: watchEndDate,
            startDate: watchStartDate
          }}
        />
      ) : (
        <form
          aria-label={
            promoDeal ? 'hotel-express-deal-search-form' : 'hotel-search-form'
          }
          onSubmit={handleSubmit((data: HOTEL_FORM_STATE_TYPE) => {
            onSubmit(
              {
                ...data,
                ...(SEMSearch && { SEMSearch: SEMSearch as SEM_SEARCH_TYPE }),
                ...(SEMSEARCH && { SEMSEARCH: SEMSEARCH as SEM_SEARCH_TYPE }),
                mapView: false
              },
              pageName
            )
          })}
        >
          <StickyEffect
            enabled={enableStickyEffect}
            isStickyInEffect={isStickyInEffect}
            handleStickyEffectStateChange={handleStickyEffectStateChange}
          >
            <Flex
              key={0}
              mx={-2}
              flexWrap={['wrap', isOneLineSearch ? null : 'wrap']}
            >
              {hasFlight && (
                <Box
                  width={typeaheadWidth}
                  mb={inputFormMargin}
                  pl={2}
                  pr={isOneLineSearch ? 1 : 2}
                  data-testid="DASH_TAB_HOTELS_STARTLOCATION"
                >
                  <ShadowEffect>
                    {({
                      disableShadowState
                    }: {
                      disableShadowState: () => void
                    }) => (
                      <Box textAlign="left">
                        <TypeAhead
                          shouldDisplayPenny={shouldDisplayPenny}
                          isTwoLineDisplay={!hasFlight}
                          searchProduct="packagesOrigin"
                          label="Where from?"
                          placeholder="Where from?"
                          searchKey="startLocation"
                          setValue={setValue}
                          errors={errors}
                          disableShadowState={disableShadowState}
                          defaultSelectedItem={watchStartLocation}
                          ref={registerTabElement}
                          showCurrentLocation={showCurrentLocation}
                          onItemSelect={itemSelected}
                        />
                      </Box>
                    )}
                  </ShadowEffect>
                </Box>
              )}
              <Box
                width={typeaheadWidth}
                mb={[showCityDisambiguation ? -1 : 0, null, 3]}
                pl={hasFlight && isOneLineSearch ? 1 : 2}
                pr={isOneLineSearch ? 1 : 2}
                data-testid="DASH_TAB_HOTELS_ENDLOCATION"
              >
                <ShadowEffect>
                  {({
                    disableShadowState
                  }: {
                    disableShadowState: () => void
                  }) => (
                    <Box textAlign="left">
                      <TypeAhead
                        shouldDisplayPenny={shouldDisplayPenny}
                        isTwoLineDisplay={!hasFlight}
                        searchProduct="hotels"
                        label="Where to?"
                        placeholder="Where to?"
                        searchKey="endLocation"
                        setValue={setValue}
                        errors={errors}
                        recommendedCityIds={recommendedCityIds}
                        disableShadowState={disableShadowState}
                        defaultSelectedItem={watchEndLocation}
                        ref={(elem: HTMLInputElement) => {
                          registerTabElement(elem)
                          if (showCityDisambiguation) {
                            registerAmbiguousElement(elem)
                          }
                        }}
                        showCurrentLocation={showCurrentLocation}
                        onItemSelect={itemSelected}
                        enableFetchedRecentSearches={isHomepage}
                      />
                    </Box>
                  )}
                </ShadowEffect>
                {showCityDisambiguation && (
                  <Box py={[2, null, 1]}>
                    <Button
                      variation="link"
                      onClick={(e: SyntheticEvent) => {
                        e.preventDefault()
                        clarifyAmbiguity()
                      }}
                    >
                      <Text fontSize={0}>
                        Looking for a different {cityName}?
                      </Text>
                    </Button>
                  </Box>
                )}
              </Box>
              <Box
                width={calendarWidth}
                px={isOneLineSearch ? 1 : 2}
                data-testid="DASH_TAB_HOTELS_DATES"
                textAlign="left"
              >
                <ShadowEffect alternativeBlurHandling>
                  {({
                    disableShadowState
                  }: {
                    disableShadowState: () => void
                  }) => (
                    <PopoverContainer
                      open={isHotelCalendarOpen}
                      data-calendar
                      onDismiss={() => {
                        setIsHotelCalendarOpen(false)
                        disableShadowState()
                      }}
                      domSelectorForVisuallyContainedElement={
                        CALENDAR_POPOVER_SELECTOR
                      }
                    >
                      <DateField
                        data-testid="hotel-date-range"
                        aria-controls="hotel-calander-wrapper"
                        m={0}
                        ref={registerTabElement}
                        aria-expanded={isHotelCalendarOpen ? 'true' : 'false'}
                        error={(() => {
                          if (errors.startDate) {
                            return errors.startDate.message
                          }
                          if (errors.endDate) {
                            return errors.endDate.message
                          }
                        })()}
                        label="Check-in - Check-out"
                        name="hotelDates"
                        onFocus={() => {
                          setIsHotelCalendarOpen(true)
                        }}
                        value={getHotelDateRange({
                          startDate: watchStartDate,
                          endDate: watchEndDate
                        })}
                      />
                      <CalendarWrapperBox
                        boxShadowSize={!isMobile ? 'overlay-lg' : undefined}
                        borderRadius={!isMobile ? '2xl' : undefined}
                      >
                        <Calendar
                          use2024DesignSeti
                          isMobile={isMobile}
                          roundedCorners
                          startDate={watchStartDate}
                          endDate={watchEndDate}
                          maxDaysToSelect={maxDaysToSelect}
                          maxDate={maxSelectableDate}
                          monthWidthPx={350}
                          onChange={(_, { startDate, endDate }) => {
                            if (startDate && endDate) {
                              setIsHotelCalendarOpen(false)
                              disableShadowState()
                            }
                            setValue('startDate', startDate || '', {
                              shouldDirty: true
                            })
                            setValue('endDate', endDate || '', {
                              shouldDirty: true
                            })
                          }}
                          monthsToShow={2}
                          onDismiss={() => {
                            setIsHotelCalendarOpen(false)
                            disableShadowState()
                          }}
                          useDefaultFooter
                        />
                      </CalendarWrapperBox>
                    </PopoverContainer>
                  )}
                </ShadowEffect>
              </Box>
              <Box
                width={roomSelectionWidth}
                mb={3}
                px={isOneLineSearch ? 1 : 2}
                data-testid="DASH_TAB_HOTELS_NUMROOMS"
              >
                <ShadowEffect enabled>
                  {({
                    disableShadowState
                  }: {
                    disableShadowState: () => void
                  }) => (
                    <Controller
                      control={control}
                      name="travelers"
                      defaultValue={watchTravelers}
                      render={({ field }) => (
                        <TravelerSelection
                          {...field}
                          initialValues={field.value}
                          trapFocus
                          isHotel={!hasFlight}
                          isMobile={isMobile}
                          height={56}
                          errorHeight={28}
                          borderRadius="lg"
                          onChange={(
                            updatedValues: TravelerSelectionOnChange
                          ) => {
                            field.onChange({
                              childrenAges: updatedValues.childrenAges,
                              children: updatedValues.children,
                              rooms: updatedValues.rooms,
                              adults: updatedValues.adults
                            })
                            disableShadowState()
                          }}
                          roomsEnabled
                          usePopoverPortal={
                            promoDeal === PromoDeal.EXPRESS_DEAL
                          }
                          disclaimer={showVPDisclaimer ? VP_DISCLAIMER : ''}
                          errorMessage={
                            errors?.travelers?.children?.message ||
                            errors?.travelers?.rooms?.message ||
                            (isInfantLimitReachedPackages(
                              watchTravelers.children,
                              watchTravelers.childrenAges,
                              watchTripType
                            ) &&
                              INFANT_CAPACITY_LIMIT) ||
                            ''
                          }
                          maxRoomsMessage="Need more rooms? Special group rates available with our hotel partner, hotelplanner.com"
                          maxRooms={watchTripType === STAY ? 9 : 4}
                          maxChildren={watchTripType === STAY ? 8 : undefined}
                          maxTravelers={watchTripType === STAY ? 36 : 8}
                          fireMaxRoomsOnClick={() => {
                            window.open(
                              hotelPlannerLinkAttrsProp.href,
                              '_blank'
                            )
                            fireMaxRoomsEvent(pageName, 'hotel')
                          }}
                          ageSelectEnabled
                          lapInfantLabel={false}
                          tooltipErrorMessageId="travelerSelectionError"
                        />
                      )}
                    />
                  )}
                </ShadowEffect>
              </Box>
              {(isMobile || isHPWithoutStickyEffect) && showBundleAndSave()}
              <Box
                width={searchButtonWidth}
                pl={isOneLineSearch ? 1 : 2}
                pr={2}
                mb={showCityMapInMobile ? 0 : searchBtnMargin}
                ml="center"
                style={{
                  overflowY: !isMobile ? 'visible' : undefined,
                  height:
                    !isMobile && (isStickyInEffect || !isHomepage)
                      ? '56px'
                      : undefined
                }}
              >
                <SearchFormButton
                  buttonText="Find Your Hotel"
                  data-testid="HOTELS_SUBMIT_BUTTON"
                  handleClick={() => {
                    if (
                      isMobile &&
                      isHomepage &&
                      typeof window.PCLN?.pclnEventDispatcher?.dispatch ===
                        'function'
                    ) {
                      window.PCLN.pclnEventDispatcher.dispatch(
                        'FIND YOUR HOTEL'
                      )
                    }
                  }}
                />

                {promoDeal !== PromoDeal.COLLECTIONS &&
                  promoDeal !== PromoDeal.EXPRESS_DEAL && (
                    <Box
                      pt={2}
                      mt={1}
                      textAlign={
                        isMobile || isHPWithoutStickyEffect ? 'center' : null
                      }
                    >
                      <Text fontSize={0}>
                        Book a hotel with free cancellation for flexibility
                      </Text>
                    </Box>
                  )}
              </Box>
              {showCityMapInMobile &&
                cityMapURL !== 'None' &&
                (!cityMapURL ? (
                  <Box data-testid="city-map-shimmer" width={1} mx={2}>
                    <Shimmer
                      height={MOBILE_CITY_MAP_HEIGHT_CONTAINER}
                      width={1}
                      borderRadius="lg"
                    />
                  </Box>
                ) : (
                  <StyledRelative
                    mb={10}
                    width={1}
                    height={MOBILE_CITY_MAP_HEIGHT_CONTAINER}
                    borderRadius="lg"
                    mx={2}
                  >
                    <Image
                      src={cityMapURL}
                      alt={`Map of ${cityName}`}
                      px={0}
                      width={1}
                      height="auto"
                    />
                    <StyledAbsolute>
                      <Button
                        size="small"
                        data-testid="open-map-view"
                        onClick={handleSubmit((data: HOTEL_FORM_STATE_TYPE) =>
                          onSubmit(
                            {
                              ...data,
                              ...(SEMSearch && {
                                SEMSearch: SEMSearch as SEM_SEARCH_TYPE
                              }),
                              mapView: true
                            },
                            pageName
                          )
                        )}
                      >
                        <Flex alignItems="center">
                          <Pin size={14} mr={1} />
                          <Text>View Hotels on Map</Text>
                        </Flex>
                      </Button>
                    </StyledAbsolute>
                  </StyledRelative>
                ))}
              {!isMobile && !isHPWithoutStickyEffect && showBundleAndSave()}
            </Flex>
          </StickyEffect>
        </form>
      )}
    </>
  )
}

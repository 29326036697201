import { HOTEL_FORM_STATE_TYPE } from '@/components/Hotels/types'
import React, { useMemo, useState } from 'react'

type SearchFormContextType<T = HOTEL_FORM_STATE_TYPE> = {
  updateFormValues: (formValues: T) => void
  formValues: T
}

export const SearchFormContext = React.createContext(
  {} as SearchFormContextType
)

function SearchFormProvider({
  children
}: {
  children: JSX.Element | JSX.Element[]
}) {
  const [formValues, updateFormValues] = useState<HOTEL_FORM_STATE_TYPE>(
    {} as HOTEL_FORM_STATE_TYPE
  )

  const value = useMemo(
    () => ({
      formValues,
      updateFormValues
    }),
    [formValues]
  )

  return (
    // TODO: Potentially impacting performance. Should be investigated

    <SearchFormContext.Provider value={value}>
      {children}
    </SearchFormContext.Provider>
  )
}

export default SearchFormProvider

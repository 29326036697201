import { useCallback, useEffect, useState } from 'react'
import pclnRequest from '@pcln/request'
import config from 'isomorphic-config'
import analytics from '@/shared-utils/analytics'
import useBootstrapData from './useBootstrapData'

type PCLN_GRAPH = {
  url: string
  timeout: number
}

type CITY_MAP_PROPS = {
  lat: number | undefined
  lon: number | undefined
  width: number | undefined
  height: number | undefined
}

const useFetchCityMap = ({ lat, lon, width, height }: CITY_MAP_PROPS) => {
  const { appName, appVersion } = useBootstrapData()
  const [cityMapURL, setCityMapURL] = useState('')

  const fetchCityMap = useCallback(
    async ({
      lat: cityLat,
      lon: cityLon,
      width: cityMapWidth,
      height: cityMapHeight
    }: CITY_MAP_PROPS) => {
      try {
        const OPERATION_NAME = 'fetchCityMap'
        const { url, timeout } = config.client['pcln-graph'] as PCLN_GRAPH
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const result: any = await pclnRequest({
          url: `${url}?gqlOp=${OPERATION_NAME}`,
          timeout,
          method: 'POST',
          headers: {
            'apollographql-client-name': appName,
            'apollographql-client-version': appVersion
          },
          data: {
            query: `query fetchCityMap($lat: Float!, $lon: Float!, $x: Int!, $y: Int!){ 
                    googleMapStatic(
                      center:{ lat: $lat, lon: $lon },
                      size: { x: $x, y: $y}
                    ) {
                      url
                    }
                  }`,
            variables: {
              lat: cityLat,
              lon: cityLon,
              x: cityMapWidth,
              y: cityMapHeight
            },
            operationName: OPERATION_NAME
          }
        })

        setCityMapURL(result?.data?.data?.googleMapStatic?.url ?? '')
      } catch (error) {
        analytics.logError({
          message: 'Failed to load city map url'
        })
        setCityMapURL('None')
      }
    },
    [appName, appVersion]
  )

  useEffect(() => {
    if (
      lat !== undefined &&
      lon !== undefined &&
      height !== undefined &&
      width !== undefined
    ) {
      void fetchCityMap({ lat, lon, width, height })
    }
  }, [lat, lon, width, height, fetchCityMap])

  return { cityMapURL, fetchCityMap }
}

export default useFetchCityMap

import analytics from '@/shared-utils/analytics'
import { type GA4PageNameType } from '@/ga4types'
import { HOTEL_FORM_STATE_TYPE } from './types'
import { GA4TripTypeMapper } from '../BundleAndSave/utils'

export default function fireHotelSubmitEvent(
  initialState: HOTEL_FORM_STATE_TYPE,
  submittedState: HOTEL_FORM_STATE_TYPE,
  pageName: GA4PageNameType
) {
  analytics.fireGA4Event({
    event: 'search',
    attributes: {
      date_change:
        initialState.startDate !== submittedState.startDate ||
        initialState.endDate !== submittedState.endDate
          ? 'y'
          : 'n',
      trip_start_city_change:
        initialState.startLocation !== submittedState.startLocation ? 'y' : 'n',
      trip_end_city_change:
        initialState.endLocation !== submittedState.endLocation ? 'y' : 'n',
      quantity_change:
        initialState.travelers.adults !== submittedState.travelers.adults ||
        initialState.travelers.children !== submittedState.travelers.children ||
        initialState.travelers.rooms !== submittedState.travelers.rooms
          ? 'y'
          : 'n',
      page_name: pageName,
      product_name: 'hotel'
    }
  })
}

export function fireBundleAndSaveEvent(tripType: string) {
  const mappedTripType = GA4TripTypeMapper(tripType)
  analytics.fireGA4Event({
    event: 'select_promotion',
    attributes: {
      type: 'add_on',
      product_name: 'hotel',
      bundle_flag: 'y',
      bundle_type: mappedTripType
    }
  })
}

export function fireMultiHotelRadioClick() {
  analytics.fireGA4Event({
    event: 'select_promotion',
    attributes: {
      type: 'package',
      page_name: 'landing',
      product_name: 'hotel',
      bundle_flag: 'y',
      bundle_type: 'hh'
    }
  })
}

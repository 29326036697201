import * as yup from 'yup'
import { NewTravelerState } from '@/types'
import type { ChildrenAges } from '@pcln/traveler-selection'
import {
  LOCATION_REQUIRED,
  INVALID_DATE,
  CHILD_AGE_REQUIRED,
  BUNDLE_MAX_CAPACITY_ERROR
} from '../validation'

const MAXIMUM_BUNDLE_GUEST_COUNT = 8

export const isChildrenAgeDataValid = (childrenAges: ChildrenAges) =>
  typeof childrenAges === 'object' && childrenAges !== null
export const numOfValidAges = (childrenAges: ChildrenAges) => {
  if (typeof childrenAges === 'object' && childrenAges !== null) {
    return Object.values(childrenAges).filter(age => {
      const num = parseInt(age, 10)
      return !Number.isNaN(num) && num >= 0
    })
  }
  return []
}

function isChildrenAgeNotProvided(
  children: number,
  childrenAges: ChildrenAges
) {
  return (
    children > 0 &&
    isChildrenAgeDataValid(childrenAges) &&
    children !== numOfValidAges(childrenAges).length
  )
}
export function handleChildrenAges(this: yup.TestContext) {
  const { children, childrenAges } = this.parent as NewTravelerState
  if (isChildrenAgeNotProvided(children, childrenAges)) {
    return this.createError({
      message: CHILD_AGE_REQUIRED,
      path: this.path
    })
  }
  return true
}

export function handleRoomCapacity(this: yup.TestContext) {
  const { adults, children } = this.parent as NewTravelerState
  const travelerCount = adults + children
  const allowedTravelerCount = MAXIMUM_BUNDLE_GUEST_COUNT

  if (allowedTravelerCount < travelerCount) {
    return this.createError({
      message: BUNDLE_MAX_CAPACITY_ERROR,
      path: this.path
    })
  }

  return true
}

export const searchFormSchema = yup.object().shape({
  hotels: yup.array().of(
    yup.object().shape({
      endLocation: yup.object().nullable().required(LOCATION_REQUIRED),
      startDate: yup.string().nullable().required(INVALID_DATE),
      endDate: yup.string().nullable().required(INVALID_DATE)
    })
  ),
  travelers: yup.object().shape({
    children: yup.number().test({
      name: 'children',
      test: handleChildrenAges
    }),
    rooms: yup.number().test({
      name: 'rooms',
      test: handleRoomCapacity
    })
  })
})

import React from 'react'
import styled from 'styled-components'
import { Flex, Label, Radio } from 'pcln-design-system'
import { fireMultiHotelRadioClick } from '../../ga4'

const StyledLabel = styled(Label)`
  cursor: pointer;
`

export const FormTypeValues = {
  SINGLE: 'single',
  MULTI: 'multi'
}

type TFormTypeRadios = {
  formType: string
  onChange: (a: string) => void
}

export default function FormTypeRadios({
  formType,
  onChange
}: TFormTypeRadios) {
  return (
    <Flex mb={2}>
      <Flex mr={3} alignItems="center">
        <StyledLabel fontSize={[10, 12]} fontWeight={500} nowrap>
          <Radio
            checked={formType === FormTypeValues.SINGLE}
            onChange={() => {
              onChange(FormTypeValues.SINGLE)
            }}
          />
          Single Hotel
        </StyledLabel>
      </Flex>

      <Flex mr={3} alignItems="center">
        <StyledLabel fontSize={[10, 12]} fontWeight={500} nowrap>
          <Radio
            checked={formType === FormTypeValues.MULTI}
            onChange={() => {
              onChange(FormTypeValues.MULTI)
              fireMultiHotelRadioClick()
            }}
          />
          Multi Hotel
        </StyledLabel>
      </Flex>
    </Flex>
  )
}
